@import url("https://fonts.googleapis.com/css?family=Alata:400");

@font-face {
    font-family: RadioGrotesk;
    src: url("./fonts/RadioGrotesk-Regular.woff") format("woff");
}

.fdefed {
    background-color: #fdefed;
}

.edfeef {
    background-color: #edfeef;
}

.efedfd {
    background-color: #efedfd;
}

.fdedfb {
    background-color: #fdedfb;
}

.fbfded {
    background-color: #fbfded;
}

.fdf7ed {
    background-color: #fdf7ed;
}

.edf3fd {
    background-color: #edf3fd;
}

.f3fded {
    background-color: #f3fded;
}

.edfdf7 {
    background-color: #edfdf7;
}

.fdedf3 {
    background-color: #fdedf3;
}

.ff7e54 {
    background-color: #ff7e54;
}

.f88379 {
    background-color: #f88379;
}

.f18181 {
    background-color: #f18181;
}

.a4ddd1 {
    background-color: #a4ddd1;
}

.f8b7b4 {
    background-color: #f8b7b4;
}

.f1dabb {
    background-color: #f1dabb;
}

.d1d1d1 {
    background-color: #d1d1d1;
}

.b6b6b6 {
    background-color: #b6b6b6;
}

.background-text {
    color: #fdefed;
}

.landing-color {
    background-color: rgba(253, 247, 237, 0.5);
}

.gray-color {
    color: #333333;
}
